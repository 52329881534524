<template>
  <div class="padding-xy-0 padding-xy-5-tablet">
    <b-table
      :data="offices"
      class="scrollable clickable-row"
      :mobile-cards="false"
      :hoverable="true"
      @click="goToOffice">
      <b-table-column v-slot="props" field="name" label="Name">
        {{ props.row.name }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'JFEOffices',
  computed: {
    offices () {
      return this.$store.state.offices
    }
  },
  created () {
    this.$store.dispatch('refreshOffices')
    this.$store.commit('setTitle', 'Offices')
  },
  methods: {
    goToOffice (office) {
      this.$router.push({
        name: 'manage_office',
        params: { id: office.id }
      })
    }
  }
}
</script>
